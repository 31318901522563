import {DiffTableSideProtection} from './side-copy-protection'
import {TemplateInstance} from '@github/template-parts'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

// Given a split diff column index, calculate the side on which it's on.
//
// Returns 0 = left, 1 = right
function tableSide(index: number): number {
  return Math.floor(index / 2)
}

// swap the "add comment" button placeholder with a real one on first hover
function ensureCommentButton(row: HTMLElement) {
  const placeholder = row.querySelector<HTMLElement>('.js-comment-button-placeholder')
  if (placeholder?.parentElement) {
    const template = document.querySelector<HTMLTemplateElement>('.js-comment-button-template')
    if (!template) {
      throw new Error('missing js-comment-button-template template element!')
    }
    // Prepare template arguments from our placeholder
    // eslint-disable-next-line github/no-dataset
    const templateVars = placeholder.dataset
    if (!templateVars) {
      throw new Error('placeholder element has no dataset')
    }
    // To avoid duplication, pull this from the DOM rather than a data attr
    templateVars.originalLine = row.querySelector<HTMLElement>('.blob-code-inner')?.textContent || ''
    const renderedButton = new TemplateInstance(template, templateVars)
    placeholder.parentElement.replaceChild(renderedButton, placeholder)
    if (templateVars.expandedDiff) {
      // this attr is optional so it can't be in the template
      const newButton = row.querySelector<HTMLElement>('.js-add-line-comment')
      if (newButton) newButton.setAttribute('data-expanded-diff', templateVars.expandedDiff)
    }
  }
}

// TODO Consider js- class names for these selectors. Though that might add
// some considerable weight to the serialized diff page.
function onHover(td: Element, toggle: boolean) {
  const row = td.parentElement
  if (!row) return

  ensureCommentButton(row)
  updateIsHoveredClasses(row, td, toggle)
}

function updateIsHoveredClasses(row: HTMLElement, td: Element, toggle: boolean) {
  const cells = row.children

  let diffSide
  if (cells.length === 4) {
    for (let i = 0, len = cells.length; i < len; i++) {
      const el = cells[i]
      if (el === td) {
        diffSide = tableSide(i)
      }
    }
  }

  for (let i = 0, len = cells.length; i < len; i++) {
    const el = cells[i]!
    if (diffSide == null || tableSide(i) === diffSide) {
      el.classList.toggle('is-hovered', toggle)
    }
  }
}

observe('.diff-table', function (container) {
  let target: Element | null = null

  function onMouseEnter() {
    if (target) {
      onHover(target, false)
    }
    target = null
  }

  function onMouseOver(event: Event) {
    if (target) {
      onHover(target, false)
    }

    if (event.target instanceof HTMLElement) {
      target = event.target.closest('td.blob-code')
      if (target) {
        onHover(target, true)
      }
    }
  }

  function add() {
    container.addEventListener('mouseenter', onMouseEnter)
    container.addEventListener('mouseleave', onMouseEnter)
    container.addEventListener('mouseover', onMouseOver)
  }

  function remove() {
    container.removeEventListener('mouseenter', onMouseEnter)
    container.removeEventListener('mouseleave', onMouseEnter)
    container.removeEventListener('mouseover', onMouseOver)
  }

  return {add, remove}
})

function copySidedContent(event: ClipboardEvent) {
  const selection = document.getSelection()

  if (selection) {
    const protector = new DiffTableSideProtection(selection)

    if (protector.isSideProtected()) {
      event.clipboardData?.setData('text/plain', protector.contentToString())
      event.preventDefault()
    }
  }
}

function clearSidedProtection(event: MouseEvent) {
  const selection = document.getSelection()
  const rightButtonClicked = event.button === 2

  if (selection && !rightButtonClicked) {
    const protector = new DiffTableSideProtection(selection)

    if (protector.isSideProtected()) {
      protector.clearSelectedText()
      protector.clearSideProtection()
    }
  }
}

function protectSidedContent() {
  const selection = document.getSelection()

  if (selection) {
    const protector = new DiffTableSideProtection(selection)

    if (protector.canBeSideProtected()) {
      protector.applySideProtection()
    } else {
      protector.clearSideProtection()
    }
  }
}

observe('.js-diff-container', function () {
  function add() {
    document.addEventListener('copy', copySidedContent)
    document.addEventListener('mousedown', clearSidedProtection)
    document.addEventListener('selectionchange', protectSidedContent)
  }

  function remove() {
    document.removeEventListener('copy', copySidedContent)
    document.removeEventListener('mousedown', clearSidedProtection)
    document.removeEventListener('selectionchange', protectSidedContent)
  }

  return {add, remove}
})
