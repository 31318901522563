import {changeValue} from '@github-ui/form-utils'

const markdownPrefix = '```suggestion'
const markdownSuffix = '```'
const markdownPrefixRegex = new RegExp(`${markdownPrefix}(?:.*)\n`, 'i')
const markdownSuffixRegex = new RegExp(`${markdownSuffix}(\n|$)`)

function lineAtIndex(input: string, index: number): string {
  const lines = input.slice(0, index).split('\n')
  return input.split('\n')[lines.length - 1]!
}

function leadingWhitespace(line: string): string {
  const captureLeadingWhitespaceRegex = /^(\s+)/
  const match = captureLeadingWhitespaceRegex.exec(line)
  return (match && match[0]) || ''
}

function formatSuggestion(input: string) {
  let suggestedChange = `${markdownPrefix}\n`
  suggestedChange += `${input}\n`
  suggestedChange += markdownSuffix

  return suggestedChange
}

function findNextOccurrenceOf(regex: RegExp, input: string, cursor = 0): number {
  const str = input.substring(cursor, input.length)
  const match = regex.exec(str)
  if (match) {
    return match.index + cursor
  } else {
    return -1
  }
}

function cursorInSuggestionBlock(input: string, cursorIndex: number): boolean {
  const ranges = []
  let cursor = 0
  let startIndex = -1
  while ((startIndex = findNextOccurrenceOf(markdownPrefixRegex, input, cursor)) > -1) {
    cursor = startIndex + markdownPrefix.length
    const endIndex = findNextOccurrenceOf(markdownSuffixRegex, input, cursor)
    if (endIndex === -1) {
      // invalid diff block
      return false
    }
    cursor = endIndex + markdownSuffix.length
    ranges.push([startIndex, endIndex])
  }
  return ranges.some(range => {
    return cursorIndex > range[0]! && cursorIndex < range[1]!
  })
}

export function insertNewSuggestionLine(textarea: HTMLTextAreaElement): boolean {
  const cursorIndex = textarea.selectionStart
  if (!cursorInSuggestionBlock(textarea.value, cursorIndex)) {
    return false
  }
  const value = textarea.value
  const currentLine = lineAtIndex(value, cursorIndex)
  if (currentLine === null) {
    return false
  }
  const whitespace = leadingWhitespace(currentLine)
  const lineToInsert = `\n${whitespace}`
  const output = value.substr(0, cursorIndex) + lineToInsert + value.substr(cursorIndex)
  changeValue(textarea, output)
  const newCursorIndex = cursorIndex + lineToInsert.length
  textarea.setSelectionRange(newCursorIndex, newCursorIndex)

  return true
}

export function insertSuggestionBlock(suggestion: string, textarea: HTMLTextAreaElement) {
  const template = formatSuggestion(suggestion)
  const cursorIndex = textarea.selectionStart
  const input = textarea.value
  const EOLIndex = findNextOccurrenceOf(/\n/, input, cursorIndex) // -1 if last line or only line
  const isOnlyLine = input.trim() === ''
  const line = lineAtIndex(input, cursorIndex)
  const isEmptyLine = line.trim() === ''
  const markdownSuffixLength = `\n${markdownSuffix}`.length

  let newValue = input
  let focusIndex = 0

  // logic -> https://gist.github.com/mikekavouras/62980232e5f82f57cd3660870a9f50bb
  if (isOnlyLine) {
    newValue = template
    focusIndex = newValue.length - markdownSuffixLength
  } else if (EOLIndex === -1) {
    if (isEmptyLine) {
      newValue = input + template
      focusIndex = newValue.length - markdownSuffixLength
    } else {
      newValue = [input, template].join('\n')
      focusIndex = newValue.length - markdownSuffixLength
    }
  } else if (!isEmptyLine) {
    if (cursorInSuggestionBlock(input, cursorIndex)) {
      const endOfBlockIndex = findNextOccurrenceOf(/```/, input, cursorIndex) + 3
      const beforeEndOfBlock = input.substring(0, endOfBlockIndex)
      const afterEndOfBlock = input.substring(endOfBlockIndex, input.length)
      newValue = [beforeEndOfBlock, '\n', template, afterEndOfBlock].join('')
      focusIndex = newValue.length - afterEndOfBlock.length - markdownSuffixLength
    } else {
      const beforeEOL = input.substring(0, EOLIndex)
      const afterEOL = input.substring(EOLIndex, input.length)
      newValue = [beforeEOL, '\n', template, afterEOL].join('')
      focusIndex = newValue.length - afterEOL.length - markdownSuffixLength
    }
  } else {
    const whitespace = leadingWhitespace(line)
    const BOLIndex = cursorIndex - whitespace.length
    const beforeBOL = input.substring(0, BOLIndex)
    const afterEOL = input.substring(EOLIndex, input.length)
    newValue = [beforeBOL, template, afterEOL].join('')
    focusIndex = newValue.length - afterEOL.length - markdownSuffixLength
  }

  changeValue(textarea, newValue)
  textarea.focus()
  textarea.setSelectionRange(focusIndex, focusIndex)
}
