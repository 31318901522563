// Pull Request: The Merge Button
// Toggle merge form after clicking "Merge pull request".
//
// Because the entire merge button and form are live update enabled, new updates
// could be pushed at anytime. So when the merge confirmation form is visible,
// mark it as "dirty" so it isn't swapped out via a live update when the user
// could be typing.

// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import {IncludeFragmentElement} from '@github/include-fragment-element'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {persistSubmitButtonValue} from '@github-ui/form-utils/remote-submit'
import {toggleDetailsTarget} from '../behaviors/details'
import visible from '@github-ui/visible'

on('details:toggled', '.js-pull-merging', function ({currentTarget}) {
  const forms = Array.from(currentTarget.querySelectorAll<HTMLElement>('.js-merge-pull-request'))
  const isDirty = forms.some(visible)
  for (const form of forms) {
    form.classList.toggle('is-dirty', isDirty)
  }
})

on('click', '.js-merge-box-try-again', async function ({currentTarget}) {
  const formTarget = currentTarget.getAttribute('data-form-target')!
  const targets = ['js-cleanup-branch-form', 'js-merge-branch-form', 'js-queue-branch-form', 'js-update-branch-form']
  if (!targets.includes(formTarget)) {
    return
  }
  const form = currentTarget.closest<HTMLElement>('.js-pull-merging')!.getElementsByClassName(formTarget)[0]!

  fire(form, 'submit')
})

// Automatically expand the merge form when restoring a merge message draft
document.addEventListener('session:resume', function (event: Event) {
  const restoredField = document.getElementById((event as CustomEvent).detail.targetId)
  if (restoredField) {
    const mergeContainer = restoredField.closest('.js-merge-pull-request')
    if (mergeContainer) {
      const mergeDetails = mergeContainer.closest('.js-details-container')
      if (mergeDetails) mergeDetails.classList.add('open')
    }
  }
})

// toggle the merge message box if the user checks admin override
on('change', '.js-admin-merge-override', function ({currentTarget}) {
  const mergeMessageContainer = currentTarget.closest<HTMLElement>('.js-merge-message-container')!
  const newStateValue = (currentTarget as HTMLInputElement).checked
  const mergeBox = mergeMessageContainer.querySelector<HTMLElement>('.js-merge-box')
  const autoMergeBox = mergeMessageContainer.querySelector<HTMLElement>('.js-auto-merge-box')
  const mergeForm = mergeMessageContainer.querySelector<HTMLElement>('.js-merge-form')
  const autoMergeForm = mergeMessageContainer.querySelector<HTMLElement>('.js-auto-merge-form')
  const mergeQueueForm = mergeMessageContainer.querySelector<HTMLElement>('.js-queue-branch-form')
  // the standard merge buttons (merge, squash, rebase)
  // we do not want to disable the auto merge or merge queue buttons
  // since those are always available even if requirements haven't been met
  const mergeButtons = mergeMessageContainer.querySelectorAll<HTMLButtonElement>('.merge-box-button')

  if (mergeBox) {
    mergeBox.hidden = !newStateValue
  }

  if (autoMergeBox) {
    autoMergeBox.hidden = newStateValue
  }

  if (mergeForm) {
    mergeForm.hidden = !newStateValue
  }
  if (autoMergeForm) {
    autoMergeForm.hidden = newStateValue
  }
  if (mergeQueueForm) {
    mergeQueueForm.hidden = newStateValue
  }

  for (const button of mergeButtons) {
    button.disabled = !newStateValue
  }
})

on('details-menu-selected', '.js-update-branch-method-menu', updateBranchUpdateMethod, {capture: true})

function updateBranchUpdateMethod(event: Event) {
  const item = (event as CustomEvent).detail.relatedTarget as HTMLInputElement
  const container = item.closest<HTMLElement>('.js-merge-pr')!

  container.classList.toggle('is-updating-via-merge', item.value === 'merge')
  container.classList.toggle('is-updating-via-rebase', item.value === 'rebase')

  const updateBranchType = container.querySelector<HTMLInputElement>('.js-update-branch-type')
  if (updateBranchType) {
    updateBranchType.value = item.value
  }
}

on('submit', '.js-update-branch-form', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-merge-pr')

  const updateMergeButton = container?.querySelector<HTMLButtonElement>('.btn-group-update-merge')
  if (updateMergeButton) {
    updateMergeButton.disabled = true
  }
  const updateRebaseButton = container?.querySelector<HTMLButtonElement>('.btn-group-update-rebase')
  if (updateRebaseButton) {
    updateRebaseButton.disabled = true
  }
  const updateMethod = container?.querySelector<HTMLElement>('.js-update-method-menu-button')
  if (updateMethod) {
    updateMethod.classList.add('disabled', 'user-select-none')
    updateMethod.setAttribute('aria-disabled', 'true')
  }
  const updateMethodDropdown = container?.querySelector<HTMLElement>('.js-update-branch-method-menu')
  if (updateMethodDropdown) {
    updateMethodDropdown?.parentNode?.removeChild(updateMethodDropdown)
  }
})

on('details-menu-selected', '.js-merge-method-menu', updateForMergeMethod, {capture: true})

function updateForMergeMethod(event: Event) {
  const item = (event as CustomEvent).detail.relatedTarget as HTMLInputElement
  const container = item.closest<HTMLElement>('.js-merge-pr')!
  const mergeForm = container.querySelector<HTMLFormElement>('.js-merge-pull-request')!

  const mergeMenuButton = container.querySelector<HTMLElement>('.js-merge-method-menu-button')!
  const buttonClass = mergeMenuButton.getAttribute('data-merge-button-class')
  mergeMenuButton.classList.toggle('btn-danger', item.hasAttribute('data-dangerous-action'))
  if (buttonClass) mergeMenuButton.classList.toggle(buttonClass, !item.hasAttribute('data-dangerous-action'))

  const titleInputLists = container.querySelectorAll<HTMLInputElement>('.js-merge-title')
  const msgTextAreaLists = container.querySelectorAll<HTMLTextAreaElement>('.js-merge-message')

  // Fix for https://github.com/github/pull-requests/issues/9718
  // Currently we have two separate drop down menu for merge/auto merge options
  const allButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.js-merge-method-menu button')
  for (const button of allButtons) {
    button.setAttribute('aria-checked', button.value === item.value ? 'true' : 'false')
  }

  for (const titleInput of titleInputLists) {
    if (titleInput.defaultValue === titleInput.value) {
      titleInput.defaultValue = titleInput.value = item.getAttribute('data-input-title-value')!
    }
  }
  for (const msgTextArea of msgTextAreaLists) {
    if (msgTextArea.defaultValue === msgTextArea.value) {
      msgTextArea.defaultValue = msgTextArea.value = item.getAttribute('data-input-message-value')!
    }
  }

  container.classList.toggle('is-merging', item.value === 'merge')
  container.classList.toggle('is-squashing', item.value === 'squash')
  container.classList.toggle('is-rebasing', item.value === 'rebase')
  container.classList.toggle('is-merging-group', item.value === 'group')
  container.classList.toggle('is-merging-solo', item.value === 'solo')
  container.classList.toggle('is-merging-jump', item.value === 'jump')

  const overrideActive =
    (mergeForm.classList.contains('js-admin-override-merge') && item.value === 'merge') ||
    (mergeForm.classList.contains('js-admin-override-squash') && item.value === 'squash') ||
    (mergeForm.classList.contains('js-admin-override-rebase') && item.value === 'rebase')

  const mergeTypeInput = container.querySelector<HTMLInputElement>('.js-merge-type')
  if (mergeTypeInput) {
    mergeTypeInput.value = item.value
  }

  const buttons = container.querySelectorAll<HTMLButtonElement>(
    '.js-merge-pull-request .js-merge-commit-button,.js-confirm-auto-merge-button',
  )
  for (const button of buttons) {
    if (item.value === button.value) {
      button.type = 'submit'

      if (button.classList.contains('js-confirm-auto-merge-button')) {
        persistSubmitButtonValue(button)
      }
    } else {
      button.type = 'button'
    }
  }

  mergeForm.classList.toggle('color-fg-danger', overrideActive)

  const liveUpdateContainer = container.closest<HTMLElement>('.js-pull-merging')!
  const dataURL = new URL(liveUpdateContainer.getAttribute('data-url')!, window.location.origin)
  const search = new URLSearchParams(dataURL.search)
  search.set('merge_type', item.value)
  dataURL.search = search.toString()
  liveUpdateContainer.setAttribute('data-url', dataURL.toString())
}

on('details:toggled', '.js-merge-pr', function (event) {
  const container = event.currentTarget
  const msgTextArea = container.querySelector('.js-merge-message')
  if (msgTextArea) {
    // Trigger size-to-fit for the merge message textarea
    fire(msgTextArea, 'change')
  }
})

observe('.branch-action-item.js-details-container.open', {
  add(container) {
    for (const details of container.querySelectorAll('.js-merge-review-section')) {
      details.setAttribute('open', '')
    }
  },
  remove(container) {
    for (const details of container.querySelectorAll('.js-merge-review-section')) {
      details.removeAttribute('open')
    }
  },
})

on(
  'toggle',
  '.js-details-container .js-merge-review-section',
  function ({currentTarget: details}) {
    const container = details.closest<HTMLElement>('.js-details-container')!
    const sections = container.querySelectorAll('.js-merge-review-section').length
    const open = container.querySelectorAll('.js-merge-review-section[open]').length
    const allSectionsCollapsed = open === 0
    const allSectionsExpanded = open === sections
    if (allSectionsCollapsed) {
      toggleDetailsTarget(container, {force: false})
    } else if (allSectionsExpanded) {
      toggleDetailsTarget(container, {force: true})
    }
  },
  {capture: true},
)

observe('poll-include-fragment[data-to-be-polled]', {
  constructor: IncludeFragmentElement,
  add(container) {
    function onVisible() {
      container.src = container.getAttribute('data-to-be-polled')!
      container.removeAttribute('data-to-be-polled')
    }

    if (document.hidden) {
      document.addEventListener('visibilitychange', onVisible, {once: true})
    } else {
      onVisible()
    }
  },
})

on('submit', '.js-remove-from-queue-form', async (event: Event) => {
  const form = event.currentTarget as HTMLFormElement
  event.preventDefault()

  const response = await fetch(form.action, {
    method: form.method,
    body: new FormData(form),
    headers: {Accept: 'application/json'},
  })

  if (response && response.ok) {
    // No action required on success state because the merge box live updates
    return
  }

  // Handle error state
  window.onbeforeunload = () => {
    window.scrollTo({top: 0})
  }
  window.location.reload()
})
