// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'
import {replaceState} from '@github-ui/browser-history-state'

on('click', '.js-rendered, .js-source', function (event) {
  const button = event.currentTarget
  if (button.classList.contains('selected')) {
    event.preventDefault()
    return
  }

  updateWindowLocation(button)
  updateSelectedButton(button)
})

remoteForm('.js-prose-diff-toggle-form', async function (form, wants) {
  const entryContainer = form.closest<HTMLElement>('.js-details-container')!
  const fileContent = entryContainer.querySelector<HTMLElement>('.js-file-content')!

  const data = await wants.html()
  while (fileContent.lastChild) {
    fileContent.removeChild(fileContent.lastChild)
  }
  fileContent.append(data.html)

  entryContainer.classList.toggle('display-rich-diff')
})

function updateWindowLocation(button: Element) {
  const fileHeader = button.closest<HTMLElement>('.js-file-header')!
  const switchToRendered = button.classList.contains('js-rendered')
  const switchToSource = button.classList.contains('js-source')
  const shortPath = fileHeader.getAttribute('data-short-path')!
  const anchor = fileHeader.getAttribute('data-anchor')!

  const url = new URL(window.location.href, window.location.origin)
  const searchParams = new URLSearchParams(url.search.slice(1))
  url.hash = anchor
  if (switchToRendered) {
    searchParams.set('short_path', shortPath)
  } else if (switchToSource) {
    searchParams.delete('short_path')
  }
  url.search = searchParams.toString()

  replaceState(null, '', url.toString())
}

function updateSelectedButton(button: Element) {
  for (const element of document.querySelectorAll('.js-rendered, .js-source')) {
    element.classList.remove('selected')
    element.removeAttribute('aria-current')
  }

  button.classList.add('selected')
  button.setAttribute('aria-current', 'true')
}
