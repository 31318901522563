import {insertNewSuggestionLine, insertSuggestionBlock} from './suggested-changes/utils'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onKey} from '@github-ui/onfocus'
import {reload} from '@github-ui/updatable-content'
import {remoteForm} from '@github/remote-form'

// validation messages found in
// app/views/comments/_suggested_changes_validation.html.erb
const VALIDATION_CLASSES = {
  addToBatchEnabled: '.js-add-to-batch-enabled',
  unchangedSuggestion: '.js-unchanged-suggestion',
  closedOrMergedPull: '.js-closed-pull',
  viewingSubset: '.js-viewing-subset-changes',
  leftBlob: '.js-validation-on-left-blob',
  rightBlob: '.js-validation-on-right-blob',
  outdatedComment: '.js-outdated-comment',
  resolvedThread: '.js-resolved-thread',
  pendingReview: '.js-pending-review',
  isMultiLine: '.js-is-multiline',
  inMergeQueue: '.js-in-merge-queue',
  headOidNotLoaded: '.js-head-oid-not-loaded',
}

function renderSuggestionBlock(el: Element) {
  const diff = el.getAttribute('data-lines')
  if (diff != null) {
    const container = el.closest<HTMLElement>('.js-suggested-changes-container')!
    const textarea = container.querySelector<HTMLTextAreaElement>('.js-comment-field')!
    insertSuggestionBlock(diff, textarea)
  }
}

function disableAllApplySuggestionButtons(template: DocumentFragment, message: string) {
  const applyButtons = document.querySelectorAll('.js-apply-suggestion-button')
  const disabledApplyButton = template.querySelector<HTMLElement>('.js-disabled-apply-suggestion-button')!
  for (const button of applyButtons) {
    const clonedDisabledApplyButton = disabledApplyButton.cloneNode(true) as Element

    displayButton(clonedDisabledApplyButton, message)
    button.closest<HTMLElement>('details')!.replaceWith(clonedDisabledApplyButton)
  }
}

function disableBatchSuggestionsButtons(message: string) {
  const menu = document.querySelector('.js-batched-suggested-changes-container')
  if (menu) {
    /* eslint-disable-next-line github/no-d-none */
    menu.classList.add('d-none')
  }

  for (const el of document.querySelectorAll<HTMLInputElement>('.js-apply-single-suggestion')) {
    /* eslint-disable-next-line github/no-d-none */
    el.classList.remove('d-none')
    el.disabled = true
    el.setAttribute('aria-label', message)
  }
  for (const el of document.querySelectorAll<HTMLInputElement>('.js-batched-suggested-changes-add')) {
    /* eslint-disable-next-line github/no-d-none */
    el.classList.remove('d-none')
    el.disabled = true
    el.setAttribute('aria-label', message)
  }
  for (const el of document.querySelectorAll('.js-batched-suggested-changes-remove')) {
    /* eslint-disable-next-line github/no-d-none */
    el.classList.add('d-none')
  }
  for (const el of document.querySelectorAll('.js-focus-commit-suggestions-form-button')) {
    /* eslint-disable-next-line github/no-d-none */
    el.classList.add('d-none')
  }
  for (const el of document.querySelectorAll('.js-pending-batched-suggestion-label')) {
    /* eslint-disable-next-line github/no-d-none */
    el.classList.add('d-none')
  }
}

function displayButton(button: Element, labelMessage: string) {
  button.setAttribute('aria-label', labelMessage)
  /* eslint-disable-next-line github/no-d-none */
  button.classList.remove('d-none')
}

function disableSuggestionButtonWithMessage(
  addBatchedSuggestionButton: HTMLElement | null | undefined,
  openInEditorButton: HTMLElement | null | undefined,
  message: string,
) {
  if (addBatchedSuggestionButton) {
    addBatchedSuggestionButton.setAttribute('disabled', 'disabled')
    addBatchedSuggestionButton.setAttribute('aria-label', message)
  }

  if (openInEditorButton) {
    openInEditorButton.hidden = true
  }
}

function validateOnPreview(message: string, blobContainer: HTMLElement) {
  const template = document
    .querySelector<HTMLElement>('.js-suggested-changes-inline-validation-template')!
    .cloneNode(true) as HTMLElement
  template.classList.remove('js-suggested-changes-inline-validation-template')
  const span = template.querySelector<HTMLElement>('.js-suggested-changes-inline-error-message')!
  span.textContent = message.trim()

  const parentNode = blobContainer.parentNode!
  parentNode.insertBefore(template, blobContainer.nextSibling)
}

on('click', '.js-suggested-change-toolbar-item', function (e) {
  const item = e.currentTarget
  if (item instanceof HTMLButtonElement && item.getAttribute('aria-disabled') === 'false') {
    renderSuggestionBlock(item)
  }
})

on('click', '.js-refresh-after-suggestion', reload)

export function validateInlineCommmentsContainer(el: Element) {
  const isCommitsTab = document.querySelector('.js-suggested-changes-subset-files')

  if (!isCommitsTab) {
    return
  }
  const items = el.querySelectorAll<HTMLButtonElement>('.js-suggested-change-toolbar-item')
  const klass = VALIDATION_CLASSES.viewingSubset
  if (!klass) return

  const message = document.querySelector<HTMLElement>(klass)!.textContent!.trim()
  for (const item of items) {
    item.setAttribute('aria-disabled', 'true')
    const tooltip_id = item.getAttribute('aria-describedby')
    if (!tooltip_id) continue
    const tooltip = document.getElementById(tooltip_id)
    if (!tooltip) continue
    tooltip.textContent = message
  }
}

observe('.js-inline-comments-container', {
  add(el) {
    validateInlineCommmentsContainer(el)
  },
})

observe('.js-preview-body .js-apply-changes', {
  add(el) {
    const container = el.closest('.js-suggested-changes-container')

    if (!container) {
      return el.remove()
    }

    const validateRightBlob = document.querySelector<HTMLElement>(VALIDATION_CLASSES.rightBlob)!.textContent!
    const validateLeftBlob = document.querySelector<HTMLElement>(VALIDATION_CLASSES.leftBlob)!.textContent!
    const blobContainer = el.closest<HTMLElement>('.js-suggested-changes-blob')!

    if (container.getAttribute('data-thread-side') !== '') {
      // when updating an existing comment, retrieve side details from data-thread-side
      if (container.getAttribute('data-thread-side') === 'left') {
        validateOnPreview(validateLeftBlob, blobContainer)
        return el.remove()
      }
    } else {
      // on a new comment, we don't have a thread yet so retrieve side details from inlineCommentForm instead
      const inlineCommentForm = el.closest<HTMLElement>('.js-inline-comment-form')!
      const inlineCommentFormSide = inlineCommentForm.querySelector<HTMLInputElement>('input[name="side"]')!

      if (!inlineCommentForm || !inlineCommentFormSide) {
        return el.remove()
      }

      if (inlineCommentForm && inlineCommentFormSide.value === 'left') {
        validateOnPreview(validateLeftBlob, blobContainer)
        return el.remove()
      }
    }

    const diffContainer = el.previousElementSibling!
    const originalLineContainer = diffContainer.querySelector('.js-blob-code-deletion')
    const suggestedLinesContainer = diffContainer.querySelectorAll('.js-blob-code-addition')

    if (!originalLineContainer || suggestedLinesContainer.length === 0) {
      return
    }

    const originalLine = originalLineContainer.textContent
    const suggestedLines = Array.from(suggestedLinesContainer).map(x => x.textContent)

    if (originalLine === suggestedLines.join('\n')) {
      validateOnPreview(validateRightBlob, blobContainer)
      return el.remove()
    }

    el.remove()
  },
})

observe('.js-comment-body .js-apply-changes', {
  add(el) {
    const container = el.closest('.js-comment')
    if (!container) {
      return el.remove()
    }

    // In a review thread, there is the potential for multiple suggested changes templates
    const template = container.querySelector('.js-suggested-changes-template')

    if (!(template instanceof HTMLTemplateElement)) {
      return el.remove()
    }

    const clonedFormContainer = template.content.cloneNode(true) as DocumentFragment
    const disabledApplyButton = clonedFormContainer.querySelector<HTMLElement>('.js-disabled-apply-suggestion-button')!
    let addBatchedSuggestionButton

    try {
      addBatchedSuggestionButton = clonedFormContainer.querySelector<HTMLElement>('.js-batched-suggested-changes-add')!
    } catch (err) {
      // Rethrow non-QueryErrors if we see them
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      if (err.name !== 'QueryError') {
        throw err
      }
    }
    // In case we're a new comment dropped into the files tab via live update, enable the button
    if (document.querySelectorAll('.js-suggested-changes-files-tab').length) {
      const addToBatchEnabledMessage = document.querySelector<HTMLElement>(
        VALIDATION_CLASSES.addToBatchEnabled,
      )!.textContent
      if (addBatchedSuggestionButton) {
        addBatchedSuggestionButton.removeAttribute('disabled')
        addBatchedSuggestionButton.setAttribute('aria-label', addToBatchEnabledMessage as string)
      }
    }

    const openInEditorButton = clonedFormContainer.querySelector<HTMLElement>('.js-open-in-editor')

    const pullHeaderDetails = document.querySelector<HTMLElement>('.js-pull-header-details')!
    const pullIsOpen = pullHeaderDetails && pullHeaderDetails.getAttribute('data-pull-is-open') === 'true'
    const threadContainer = el.closest('.js-resolvable-timeline-thread-container')
    const commentIsPending = template.getAttribute('data-comment-pending') === 'true'
    const inMergeQueue = template.getAttribute('data-in-merge-queue') === 'true'
    if (threadContainer && threadContainer.getAttribute('data-resolved') === 'true') {
      const resolvedThreadMessage = document.querySelector<HTMLElement>(VALIDATION_CLASSES.resolvedThread)!.textContent!
      const details = clonedFormContainer.querySelector('details')
      if (details) {
        details.remove()
      }
      displayButton(disabledApplyButton, resolvedThreadMessage)
      disableSuggestionButtonWithMessage(addBatchedSuggestionButton, openInEditorButton, resolvedThreadMessage)

      return el.replaceWith(clonedFormContainer)
    }

    if (!pullIsOpen) {
      const pullNotOpenMessage = document.querySelector<HTMLElement>(VALIDATION_CLASSES.closedOrMergedPull)!
        .textContent!
      displayButton(disabledApplyButton, pullNotOpenMessage)
      disableSuggestionButtonWithMessage(addBatchedSuggestionButton, openInEditorButton, pullNotOpenMessage)

      return el.replaceWith(clonedFormContainer)
    }

    if (inMergeQueue) {
      const inMergeQueueValidationClass = document.querySelector<HTMLElement>(VALIDATION_CLASSES.inMergeQueue)
      let inMergeQueueMessage = ''
      if (inMergeQueueValidationClass) {
        inMergeQueueMessage = inMergeQueueValidationClass.textContent || ''
      }

      const details = clonedFormContainer.querySelector('details')
      if (details) {
        details.remove()
      }

      displayButton(disabledApplyButton, inMergeQueueMessage)
      disableSuggestionButtonWithMessage(addBatchedSuggestionButton, openInEditorButton, inMergeQueueMessage)

      return el.replaceWith(clonedFormContainer)
    }

    if (commentIsPending) {
      const pendingMessage = document.querySelector<HTMLElement>(VALIDATION_CLASSES.pendingReview)!.textContent!
      clonedFormContainer.querySelector<HTMLElement>('details')!.remove()
      displayButton(disabledApplyButton, pendingMessage)
      disableSuggestionButtonWithMessage(addBatchedSuggestionButton, openInEditorButton, pendingMessage)

      return el.replaceWith(clonedFormContainer)
    }

    const commitsTab = document.querySelector('.js-suggested-changes-subset-files')
    const outdatedComment = template.getAttribute('data-outdated-comment') === 'true'
    if (commitsTab || outdatedComment) {
      let message
      if (commitsTab) {
        message = document.querySelector<HTMLElement>(VALIDATION_CLASSES.viewingSubset)!.textContent
      } else if (outdatedComment) {
        message = document.querySelector<HTMLElement>(VALIDATION_CLASSES.outdatedComment)!.textContent
      }

      clonedFormContainer.querySelector<HTMLElement>('details')!.remove()
      displayButton(disabledApplyButton, message as string)
      disableSuggestionButtonWithMessage(addBatchedSuggestionButton, openInEditorButton, message as string)

      return el.replaceWith(clonedFormContainer)
    }

    const form = clonedFormContainer.querySelector<HTMLElement>('.js-single-suggested-change-form')!
    const suggestedChangesBlob = el.closest('.js-suggested-changes-blob')

    if (suggestedChangesBlob) {
      const deletionsContainers = suggestedChangesBlob.querySelectorAll('.js-blob-code-deletion')
      const additionsContainers = suggestedChangesBlob.querySelectorAll('.js-blob-code-addition')

      if (!deletionsContainers) {
        return
      }

      const originalLines = Array.from(deletionsContainers).map(x => x.textContent!)
      const suggestedLines = Array.from(additionsContainers).map(x => x.textContent!)
      const suggestedChangesSame =
        originalLines.length === suggestedLines.length &&
        originalLines.every((line, index) => line === suggestedLines[index])
      if (suggestedChangesSame) {
        const unchangedSuggestionMessage = document.querySelector<HTMLElement>(VALIDATION_CLASSES.unchangedSuggestion)!
          .textContent!
        clonedFormContainer.querySelector<HTMLElement>('details')!.remove()
        displayButton(disabledApplyButton, unchangedSuggestionMessage)
        disableSuggestionButtonWithMessage(addBatchedSuggestionButton, openInEditorButton, unchangedSuggestionMessage)
      } else {
        for (const line of suggestedLines) {
          const input = document.createElement('input')
          input.setAttribute('type', 'hidden')
          input.setAttribute('name', 'value[]')
          input.value = line
          form.appendChild(input)
        }
      }
    }

    el.replaceWith(clonedFormContainer)
  },
})

// Reset buttons with disabled tooltipped versions when
// a PR gets closed or merged
observe('.js-pull-header-details', {
  add(el) {
    const pullIsOpen = el.getAttribute('data-pull-is-open') === 'true'
    const template = document.querySelector('.js-suggested-changes-template')

    if (pullIsOpen || !(template instanceof HTMLTemplateElement)) {
      return
    }

    const templateContent = template?.content
    const message = document.querySelector<HTMLElement>(VALIDATION_CLASSES.closedOrMergedPull)!.textContent!
    disableAllApplySuggestionButtons(templateContent, message)
    disableBatchSuggestionsButtons(message)
  },
})

// Disable all apply suggestion buttons when viewing a subset of changes
observe('.js-suggested-changes-subset-files', {
  add() {
    const template = document.querySelector('.js-suggested-changes-template')
    if (!(template instanceof HTMLTemplateElement)) {
      return
    }
    const templateContent = template.content
    const message = document.querySelector<HTMLElement>(VALIDATION_CLASSES.viewingSubset)!.textContent!
    disableAllApplySuggestionButtons(templateContent, message)
    disableBatchSuggestionsButtons(message)
  },
})

// Disable all apply suggestion buttons when the head OID of the PR has not loaded. This
// can happen when the Spokes database cluster is down.
observe('.js-suggested-changes-template', {
  add(el) {
    const headOidisLoaded = el.getAttribute('data-head-oid-is-loaded') === 'true'
    if (!(el instanceof HTMLTemplateElement) || headOidisLoaded) {
      return
    }

    const templateContent = el.content
    const message = document.querySelector<HTMLElement>(VALIDATION_CLASSES.headOidNotLoaded)?.textContent || ''
    disableAllApplySuggestionButtons(templateContent, message)
    disableBatchSuggestionsButtons(message)
  },
})

on('click', '.js-apply-suggestion-button', async function (event) {
  const details = event.currentTarget.parentElement
  if (details) {
    const titleInput = details.querySelector<HTMLElement>('.js-suggestion-commit-title')!
    setTimeout(() => titleInput.focus(), 1)
  }
})

onKey('keypress', '.js-comment-field', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  if (event.key === 'Enter') {
    const textarea = event.target as HTMLTextAreaElement
    if (insertNewSuggestionLine(textarea)) {
      event.preventDefault()
    }
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

onKey('keypress', '.js-suggestion-commit-message', function (event) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  const textarea = event.currentTarget as HTMLTextAreaElement
  if (event.key === 'Enter') {
    textarea.setAttribute('rows', '3')
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

function updatePendingSuggestionsState(menu: HTMLElement) {
  const container = menu.closest<HTMLElement>('.js-review-state-classes')!
  const batchedSuggestionsCount = container.querySelectorAll('[data-pending-batched-suggestion]').length

  for (const el of document.querySelectorAll('.js-pending-batched-suggested-changes-count')) {
    el.textContent = String(batchedSuggestionsCount)
  }

  const message = document.querySelector<HTMLElement>('.js-reenable-add-to-batch')!.textContent!
  for (const el of document.querySelectorAll('[data-batched-suggestion-reenable-sibling]')) {
    el.removeAttribute('data-batched-suggestion-reenable-sibling')
    el.removeAttribute('disabled')
    el.setAttribute('aria-label', message)
  }

  if (batchedSuggestionsCount > 0) {
    menu.hidden = false

    const button = menu.querySelector<HTMLElement>('.js-batched-suggested-changes-toggle')!
    button.classList.add('anim-pulse-in')
    button.addEventListener('animationend', () => button.classList.remove('anim-pulse-in'), {once: true})

    for (const el of document.querySelectorAll('.js-apply-single-suggestion')) {
      /* eslint-disable-next-line github/no-d-none */
      el.classList.add('d-none')
    }
    for (const el of document.querySelectorAll('.js-batched-suggested-changes-add')) {
      const parent = el.closest<HTMLElement>('.js-suggested-change-form-container')!
      if (
        parent.getAttribute('data-pending-batched-suggestion') === 'true' ||
        parent.getAttribute('data-comment-pending') === 'true' ||
        parent.getAttribute('data-outdated-comment') === 'true'
      ) {
        /* eslint-disable-next-line github/no-d-none */
        el.classList.add('d-none')
      } else {
        /* eslint-disable-next-line github/no-d-none */
        el.classList.remove('d-none')
      }

      if (el.getAttribute('data-batched-suggestion-disabled-by-sibling') === 'true') {
        el.removeAttribute('data-batched-suggestion-disabled-by-sibling')
        el.setAttribute('disabled', 'disabled')
        el.setAttribute('aria-label', document.querySelector<HTMLElement>('.js-one-suggestion-per-line')!.textContent!)
      }
    }
    for (const el of document.querySelectorAll('.js-batched-suggested-changes-remove')) {
      const parent = el.closest<HTMLElement>('.js-suggested-change-form-container')!
      if (parent.getAttribute('data-pending-batched-suggestion') === 'true') {
        /* eslint-disable-next-line github/no-d-none */
        el.classList.remove('d-none')
      } else {
        /* eslint-disable-next-line github/no-d-none */
        el.classList.add('d-none')
      }
    }
    for (const el of document.querySelectorAll('.js-focus-commit-suggestions-form-button')) {
      const parent = el.closest<HTMLElement>('.js-suggested-change-form-container')!
      const commentIsPending = parent.getAttribute('data-comment-pending') === 'true'
      const commentIsOutdated = parent.getAttribute('data-outdated-comment') === 'true'
      const commentIsResolved = parent.getAttribute('data-resolved-comment') === 'true'

      const grandParent = el.closest<HTMLElement>('.js-inline-comments-container')!
      const side = grandParent.querySelector<HTMLInputElement>('input[name="side"]')!
      const commentOnDeletedLine = side.value === 'left'

      if (commentIsPending || commentIsOutdated || commentIsResolved || commentOnDeletedLine) {
        /* eslint-disable-next-line github/no-d-none */
        el.classList.add('d-none')
      } else {
        /* eslint-disable-next-line github/no-d-none */
        el.classList.remove('d-none')
      }
    }
    for (const el of document.querySelectorAll('.js-pending-batched-suggestion-label')) {
      const parent = el.closest<HTMLElement>('.js-suggested-change-form-container')!
      if (parent.getAttribute('data-pending-batched-suggestion') === 'true') {
        /* eslint-disable-next-line github/no-d-none */
        el.classList.remove('d-none')
      } else {
        /* eslint-disable-next-line github/no-d-none */
        el.classList.add('d-none')
      }
    }
  } else {
    menu.hidden = true

    for (const el of document.querySelectorAll('.js-apply-single-suggestion')) {
      /* eslint-disable-next-line github/no-d-none */
      el.classList.remove('d-none')
    }
    for (const el of document.querySelectorAll('.js-batched-suggested-changes-add')) {
      /* eslint-disable-next-line github/no-d-none */
      el.classList.remove('d-none')
    }
    for (const el of document.querySelectorAll('.js-batched-suggested-changes-remove')) {
      /* eslint-disable-next-line github/no-d-none */
      el.classList.add('d-none')
    }
    for (const el of document.querySelectorAll('.js-focus-commit-suggestions-form-button')) {
      /* eslint-disable-next-line github/no-d-none */
      el.classList.add('d-none')
    }
    for (const el of document.querySelectorAll('.js-pending-batched-suggestion-label')) {
      /* eslint-disable-next-line github/no-d-none */
      el.classList.add('d-none')
    }
  }
}

function updatePendingSuggestionsHandler() {
  const menu = document.querySelector<HTMLElement>('.js-batched-suggested-changes-container')
  if (menu) setTimeout(() => updatePendingSuggestionsState(menu))
}

on('click', '.js-batched-suggested-changes-add', function (event) {
  const target = event.target as HTMLElement
  const parent = target.closest<HTMLElement>('.js-suggested-change-form-container')!
  if (
    parent.getAttribute('data-comment-pending') === 'true' ||
    parent.getAttribute('data-outdated-comment') === 'true' ||
    target.getAttribute('data-batched-suggestion-disabled-by-sibling') === 'true'
  ) {
    return
  }

  parent.setAttribute('data-pending-batched-suggestion', 'true')

  const grandParent = target.closest<HTMLElement>('.js-inline-comments-container')!
  for (const el of grandParent.querySelectorAll('.js-batched-suggested-changes-add')) {
    el.setAttribute('data-batched-suggestion-disabled-by-sibling', 'true')
  }
  target.removeAttribute('data-batched-suggestion-disabled-by-sibling')

  updatePendingSuggestionsHandler()
})

on('click', '.js-batched-suggested-changes-remove', function (event) {
  const parent = event.currentTarget.closest<HTMLElement>('.js-suggested-change-form-container')!
  parent.removeAttribute('data-pending-batched-suggestion')

  const grandParent = event.currentTarget.closest<HTMLElement>('.js-inline-comments-container')!
  for (const el of grandParent.querySelectorAll('.js-batched-suggested-changes-add')) {
    el.setAttribute('data-batched-suggestion-reenable-sibling', 'true')
  }

  updatePendingSuggestionsHandler()
})

on('click', '.js-focus-commit-suggestions-form-button', function (event) {
  event.preventDefault()
  window.location.href = '#clear-commit-suggestions'
  window.location.href = '#commit-suggestions'
})

on('click', '.js-dismiss-batched-suggested-changes-onboarding-notice', async function (event) {
  const dismissUrl = event.currentTarget.getAttribute('data-url')!
  const token = event.currentTarget.parentElement!.querySelector<HTMLInputElement>('.js-data-url-csrf')!
  const data = new FormData()
  data.append('notice', 'batched_suggested_changes_onboarding_prompt')
  const response = await fetch(dismissUrl, {
    method: 'POST',
    mode: 'same-origin',
    body: data,
    headers: {
      'Scoped-CSRF-Token': token.value,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (response.ok) {
    for (const notice of document.querySelectorAll('.js-batched-suggested-change-onboarding-notice')) {
      notice.remove()
    }
  }
})

interface Change {
  commentId: string
  path: string
  suggestion: string[]
}

function convertFormInputs(form: HTMLFormElement, changes: Change[]) {
  const commitTitleInput = form.querySelector<HTMLInputElement>('input[name=commit_title]')!
  const commitMessageInput = form.querySelector<HTMLTextAreaElement>('textarea[name=commit_message]')!
  let message = commitTitleInput.value.trim()
  if (message === '') {
    message = commitTitleInput.defaultValue
  }
  const commitMessage = commitMessageInput.value.trim()
  if (commitMessage !== '') {
    message = `${message}\n\n${commitMessage}\n`
  }
  commitTitleInput.disabled = true
  commitMessageInput.disabled = true

  const messageInput = document.createElement('input')
  messageInput.setAttribute('type', 'hidden')
  messageInput.setAttribute('name', 'message')
  messageInput.value = message
  form.appendChild(messageInput)

  const changesInput = document.createElement('input')
  changesInput.setAttribute('type', 'hidden')
  changesInput.setAttribute('name', 'changes')
  changesInput.value = JSON.stringify(changes)
  form.appendChild(changesInput)
}

on('click', '.js-single-suggested-change-form .js-suggested-changes-submit', function (event) {
  const submitButton = event.currentTarget
  const form = submitButton.closest<HTMLFormElement>('.js-single-suggested-change-form')!

  const lineValues = Array.from(form.querySelectorAll<HTMLInputElement>('input[name="value[]"]')).map(i => i.value)
  const changes = [
    {
      commentId: form.querySelector<HTMLInputElement>('input[name=comment_id]')!.value,
      path: form.querySelector<HTMLInputElement>('input[name=path]')!.value,
      suggestion: lineValues,
    },
  ]

  convertFormInputs(form, changes)
})

remoteForm('.js-single-suggested-change-form', async function (form, wants) {
  const formContainer = form.closest<HTMLElement>('.js-suggested-change-form-container')!
  const dropdown = form.closest<HTMLElement>('details')!
  const appliedButton = formContainer.querySelector<HTMLElement>('.js-suggestion-applied')!
  const containerParent = form.closest<HTMLElement>('.js-suggested-changes-contents')!
  const errorMessageContainer = containerParent.querySelector<HTMLElement>('.js-error-message-placeholder')!

  try {
    await wants.json()
    /* eslint-disable-next-line github/no-d-none */
    appliedButton.classList.remove('d-none')
    const addToBatchButton = formContainer.querySelector<HTMLElement>('.js-batched-suggested-changes-add')!
    /* eslint-disable-next-line github/no-d-none */
    addToBatchButton.classList.add('d-none')

    dropdown.remove()
    reload()
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (error.name !== 'QueryError') {
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      const errorMessage = error.response.json && error.response.json.error
      const errorMessageElement = errorMessageContainer.querySelector<HTMLElement>('.js-error-message')!

      errorMessageContainer.hidden = false
      errorMessageElement.textContent = errorMessage
      formContainer.prepend(errorMessageContainer)

      const buttonContainer = form.closest<HTMLElement>('.js-suggested-change-form-container')!
      const addToBatchButton = buttonContainer.querySelector<HTMLElement>('.js-batched-suggested-changes-add')!
      /* eslint-disable-next-line github/no-d-none */
      addToBatchButton.classList.add('d-none')
      dropdown.remove()
    }
  }
})

on('click', '.js-suggestion-batch-submit', function (event) {
  const form = event.currentTarget.closest<HTMLFormElement>('.js-batched-suggested-changes-form')!
  const changes = []
  for (const el of document.querySelectorAll('[data-pending-batched-suggestion]')) {
    const changeForm = el.querySelector<HTMLFormElement>('.js-single-suggested-change-form')!
    const lineValues = Array.from(changeForm.querySelectorAll<HTMLInputElement>('input[name="value[]"]')).map(
      i => i.value,
    )
    changes.push({
      commentId: changeForm.querySelector<HTMLInputElement>('input[name=comment_id]')!.value,
      path: changeForm.querySelector<HTMLInputElement>('input[name=path]')!.value,
      suggestion: lineValues,
    })
  }
  convertFormInputs(form, changes)
})

remoteForm('.js-batched-suggested-changes-form', async function (form, wants) {
  try {
    await wants.json()
    const menu = form.closest<HTMLElement>('.js-batched-suggested-changes-container')!
    menu.hidden = true
    reload()
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    const errorMessage = error.response.json && error.response.json.error
    const formParent = form.closest<HTMLElement>('.js-batched-suggested-changes-container')!
    const errorMessageContainer = formParent.querySelector<HTMLElement>('.js-error-message-container')!
    const errorMessageSpan = errorMessageContainer.querySelector<HTMLElement>('.js-error-message')!
    errorMessageSpan.textContent = errorMessage
    errorMessageContainer.hidden = false
  }
})

observe('.js-files-tab-stale', {
  add() {
    const menu = document.querySelector<HTMLElement>('.js-batched-suggested-changes-container')
    if (menu) {
      setTimeout(() => {
        menu.hidden = true
      })
    }
  },
})
