// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('click', '.js-split-diff-view', function () {
  const unifiedDiffViewBox = document.querySelector<HTMLElement>('.js-unified-diff-view-box')!
  const splitDiffViewBox = document.querySelector<HTMLElement>('.js-split-diff-view-box')!

  const splitDiffViewOption = document.querySelector<HTMLInputElement>('.js-split-diff-view-option')!
  const unifiedDiffViewOption = document.querySelector<HTMLInputElement>('.js-unified-diff-view-option')!

  splitDiffViewBox.classList.add('color-border-accent-emphasis')
  splitDiffViewBox.classList.remove('color-border-default')

  unifiedDiffViewBox.classList.remove('color-border-accent-emphasis')
  unifiedDiffViewBox.classList.add('color-border-default')

  unifiedDiffViewOption.checked = false
  splitDiffViewOption.checked = true
})

on('click', '.js-unified-diff-view', function () {
  const unifiedDiffViewBox = document.querySelector<HTMLElement>('.js-unified-diff-view-box')!
  const splitDiffViewBox = document.querySelector<HTMLElement>('.js-split-diff-view-box')!

  const splitDiffViewOption = document.querySelector<HTMLInputElement>('.js-split-diff-view-option')!
  const unifiedDiffViewOption = document.querySelector<HTMLInputElement>('.js-unified-diff-view-option')!

  splitDiffViewBox.classList.remove('color-border-accent-emphasis')
  splitDiffViewBox.classList.add('color-border-default')

  unifiedDiffViewBox.classList.add('color-border-accent-emphasis')
  unifiedDiffViewBox.classList.remove('color-border-default')

  unifiedDiffViewOption.checked = true
  splitDiffViewOption.checked = false
})
