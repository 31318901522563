import {controller} from '@github/catalyst'
import {eventToHotkeyString} from '@github-ui/hotkey'
import {sendHydroEvent} from '../../github/hydro-tracking'

// Track CTRL+F usage on Files changed tab
// to determine the cost of virtualizing the list of patches
@controller
class InstrumentFilesElement extends HTMLElement {
  connectedCallback() {
    document.addEventListener('keydown', this.instrumentCtrlF)
  }

  disconnectedCallback() {
    document.removeEventListener('keydown', this.instrumentCtrlF)
  }

  instrumentCtrlF = (event: KeyboardEvent) => {
    const findCommands = ['Control+f', 'Meta+f']
    const hotkeyString = eventToHotkeyString(event)
    if (findCommands.includes(hotkeyString)) {
      sendHydroEvent(this)
    }
  }
}
