import {getState, replaceState} from '@github-ui/browser-history-state'

export function updateURLState(
  fileFiltersActive: boolean,
  whitespaceFilterActive: boolean,
  selectedTypes: string[],
  selectedOwnersActive: boolean,
  selectedOwners: string[],
  manifestsFilterActive: boolean,
  deletedFilesHidden: boolean,
  viewedFilesHidden: boolean,
  // useful for testing
  searchParamString = window.location.search,
) {
  const fileFilter = document.querySelector<HTMLElement>('.js-file-filter')!
  const url = new URL(window.location.href, window.location.origin)
  const activeSearchParams = new URLSearchParams(searchParamString)
  const whitespaceParam = activeSearchParams.get('w')

  if (!fileFiltersActive && !selectedOwnersActive) {
    if (manifestsFilterActive) {
      return
    }

    // keep the 'diff' and 'w' params if the file filter are not active
    const params = new URLSearchParams()
    const diffParam = activeSearchParams.get('diff')
    if (diffParam) params.set('diff', diffParam)
    if (whitespaceParam) params.set('w', whitespaceParam)

    url.search = params.toString()
    replaceState(getState(), '', url.toString())
    return
  }

  const fileFilterForm = fileFilter.querySelector<HTMLFormElement>('.js-file-filter-form')!
  const formData = new FormData(fileFilterForm)
  const disabledSelectedTypes = selectedTypes.filter(type => !formData.getAll('file-filters[]').includes(type))
  const disabledSelectedOwners = selectedOwners.filter(owner => !formData.getAll('owned-by[]').includes(owner))

  // Add selected but disabled types
  for (const disabledType of disabledSelectedTypes) {
    formData.append('file-filters[]', disabledType)
  }

  for (const disabledOwner of disabledSelectedOwners) {
    formData.append('owned-by[]', disabledOwner)
  }

  const params = new URLSearchParams(formData as unknown as URLSearchParams)

  if (!params.has('file-filters[]') || params.toString() === '') {
    // Filter is active, but no file types are selected
    params.set('file-filters[]', '')
  }

  if (whitespaceFilterActive) {
    params.set('w', whitespaceParam ?? '1')
  }

  if (manifestsFilterActive) {
    params.set('manifests', 'true')
  }

  if (deletedFilesHidden) {
    params.set('show-deleted-files', 'false')
  }

  if (viewedFilesHidden) {
    params.set('show-viewed-files', 'false')
  }

  /* eslint-disable-next-line github/authenticity-token */
  params.delete('authenticity_token')

  url.search = params.toString()
  replaceState(getState(), '', url.toString())
}
