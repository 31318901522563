// Diffs: [ ] Show annotations
//
// Toggle inline annotations in files

// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('change', '.js-toggle-file-check-annotations', function (event) {
  const target = event.currentTarget as HTMLInputElement

  const inlineAnnotations = target.closest<HTMLElement>('.file')!.querySelectorAll('.js-inline-annotations')

  for (const inlineAnnotation of inlineAnnotations) {
    if (!(inlineAnnotation instanceof HTMLElement)) continue
    inlineAnnotation.hidden = !target.checked
  }
})
