// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {softNavigate} from '@github-ui/soft-navigate'

// Adds .is-range-selected class to selected item range.
function highlightSelectedRange(container: Element, startItem: Element, endItem: Element) {
  const items = Array.from(container.querySelectorAll('[role="menuitem"]'))
  let startIndex = items.indexOf(startItem)
  let endIndex = items.indexOf(endItem)

  if (startIndex === -1) {
    throw new Error("Couldn't find startIndex in container")
  } else if (endIndex === -1) {
    throw new Error("Couldn't find endItem in container")
  }

  // Give last selected item a bolder highlight regardless
  // of selection direction.
  resetHighlight(container)
  items[endIndex]!.classList.add('is-last-in-range')

  // Flip start and end if reversed
  if (startIndex > endIndex) {
    ;[startIndex, endIndex] = [endIndex, startIndex]
  }

  // Replace current is-range-selected with new range
  for (const item of items.slice(startIndex, endIndex + 1)) {
    item.classList.add('is-range-selected')
  }
}

// Start process to monitor shift navigation ranges. Process starts when
// menu is activated and should terminate when menu is deactivated.
async function observeShiftRangeHighlights(container: Element, startItem: Element) {
  function highlightRange(event: Event) {
    highlightSelectedRange(container, startItem, (event.target as Element).closest<HTMLElement>('[role="menuitem"]')!)
  }

  // Mark current focus as start of the range and highlight up to latest
  // focused item as it changes.
  highlightSelectedRange(container, startItem, startItem)
  container.addEventListener('mouseover', highlightRange)

  // When shift key is released, disable range highlighting.
  await new Promise(resolve => window.addEventListener('keyup', resolve, {once: true}))
  container.removeEventListener('mouseover', highlightRange)
  resetHighlight(container)
}

function resetHighlight(menu: Element) {
  for (const item of menu.querySelectorAll('[role="menuitem"]')) {
    item.classList.remove('is-range-selected', 'is-last-in-range')
  }
}

observe('.js-diffbar-range-menu .js-diffbar-range-list', {subscribe: manageRangeHighlight})

function manageRangeHighlight(container: Element) {
  const menu = container.closest<HTMLElement>('details-menu')!
  let shifted = false

  function clicked(event: Event) {
    shifted = (event as KeyboardEvent).shiftKey
    // Cancel browser's shift-click behavior.
    if (shifted) {
      event.preventDefault()
    }
  }

  function selected(event: Event) {
    if (!shifted) return

    // Cancel details-menu close.
    event.preventDefault()

    const currentItem = (event as CustomEvent).detail.relatedTarget as Element
    if (currentItem.classList.contains('is-range-selected')) {
      event.stopPropagation()
      const items = container.querySelectorAll('.is-range-selected')
      const firstItem = items[0]!
      const lastItem = items[items.length - 1]!

      const urlTemplate = container.getAttribute('data-range-url')!
      const sha1 = firstItem.getAttribute('data-parent-commit')
      const sha2 = lastItem.getAttribute('data-commit')!

      const range = sha1 && sha2 ? `${sha1}..${sha2}` : sha2
      const url = urlTemplate.replace('$range', range)

      softNavigate(url)
    } else {
      event.stopImmediatePropagation()
      observeShiftRangeHighlights(container, currentItem)
    }
  }

  container.addEventListener('click', clicked, {capture: true})
  menu.addEventListener('details-menu-select', selected)

  return {
    unsubscribe: () => {
      container.removeEventListener('click', clicked, {capture: true})
      menu.removeEventListener('details-menu-select', selected)
    },
  }
}

on(
  'toggle',
  '.js-diffbar-range-menu',
  function (event) {
    const details = event.currentTarget
    if (!details.hasAttribute('open')) return
    const item = details.querySelector<HTMLElement>('.in-range')
    if (item) item.focus()
  },
  {capture: true},
)
