// Diffs: [ ] Show notes
//
// Toggle inline comments in the files

import {changeValue} from '@github-ui/form-utils'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('change', '.js-toggle-file-notes', function (event) {
  const target = event.currentTarget as HTMLInputElement
  target.closest<HTMLElement>('.file')!.classList.toggle('show-inline-notes', target.checked)
})

function toggleCheckboxes(checkboxes: HTMLInputElement[]) {
  const anyChecked = checkboxes.some(el => el.checked)
  for (const checkbox of checkboxes) {
    changeValue(checkbox, !anyChecked)
  }
}

// Keyboard shortcut for toggling all inline comments
on('click', '.js-toggle-all-file-notes', function (event) {
  const checkboxes = Array.from(document.querySelectorAll<HTMLInputElement>('.js-toggle-file-notes'))
  toggleCheckboxes(checkboxes)
  event.preventDefault()
})

// Keyboard shortcut for toggling all inline annotations
on('click', '.js-toggle-all-file-annotations', function (event) {
  const checkboxes = Array.from(document.querySelectorAll<HTMLInputElement>('.js-toggle-file-check-annotations'))
  toggleCheckboxes(checkboxes)
  event.preventDefault()
})

// Hoist presence of any inline comment containers to the .file container
//
// Used to show/hide the "Show notes" checkbox
//
// CSS4 style
//
//   .file.has-inline-notes
//   .file:has(.js-inline-comments-container)
//
observe('.js-inline-comments-container', function (el) {
  let remove
  const file = el.closest('.file')
  if (file) {
    const add = (remove = function () {
      const hasNotes = file.querySelector('.js-inline-comments-container') != null
      file.classList.toggle('has-inline-notes', hasNotes)
    })

    return {
      add,
      remove,
    }
  }
})
